@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overlay-div{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

.pagination-flex .pagination{
  display: flex;
  gap: 10px;
  justify-content: end;
}
.pagination-flex {
  padding: 15px;
 
}
.pagination-flex .pagination li{
  border:1px solid #D9D9D9;
  border-radius: 4px;
  font-size: 12px;
}

.pagination-flex .pagination li a{
  padding: 5px;
}

.pagination-flex .pagination .disabled{
  display: none;
}
.pagination-flex .pagination  .active{
  background-color:#0F0F18;
  color:#fff;
}
.pagination-flex .pagination li:hover{
  background-color:#D9D9D9;
  color:#fff;
}

